section:has(section.offline) {
  position: relative;
}

.offline {
  width: 100%;
  height: 100%;
  min-height: 300px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.offline > div {
  max-width: 400px;
  color: #002f63;
  user-select: none;
  text-align: center;
  padding: 40px 20px;
  margin: auto;
}

.offline .heading {
  margin-top: 5px;
}

.offline > div > .heading:nth-child(1) {
  margin-bottom: 10px;
}

.offline button {
  margin-top: 20px;
}

.heading > span {
  position: relative;
}

.heading > span > .times {
  font-size: 0.4em;
  position: absolute;
  bottom: 15px;
  left: 40px;
  color: red;
}

.offline .btn.btnred:disabled {
  background-color: red !important;
}

.offline .btn.btngreen:disabled {
  background-color: green !important;
}
