.confirm-action-wrapper{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    backdrop-filter: blur(1px);
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
}

.action-form{
    width: 500px;
    max-width: 90%;
    border-radius: 20px;
    background-color: white;
    box-shadow: 0 3px 5px rgba(35, 18, 11, 0.4);
}

.action-form>div{
    width: 100%;
    padding: 20px;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
}

.action-nav{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    background-color: #002F63;
    border-bottom-left-radius: initial;
    border-bottom-right-radius: initial;
    box-shadow: 0 3px 5px rgba(255, 255, 255, 0.4);
}

.action-close{
    font-size: 1.5em;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

.action-close:hover{
    transform: scale(0.9);
}

.action-main{
    display: flex;
    flex-direction: column;
}

.action-note{
    font-size: 1.1em;
}

.action-warning{
    margin: 10px 0;
}

.password-input{
    width: 300px;
    max-width: 90%;
    position: relative;
}

.password-icon{
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #002F63;
}

.action-input{
    width: 100%;
    border-radius: 4px;
    border: 1px solid rgba(0, 45, 97, 0.2);
    outline: none;
    padding: 8px;
    padding-right: 30px;
    margin: 20px 5px;
    letter-spacing: 2px;
}

.action-input:focus{
    border: 1px solid rgba(0, 45, 97, 0.5);
}

.action-btn{
    width: 100px;
    background-color: #002F63;
    color: white;
    padding: 8px;
    margin-left: calc(100% - 100px);
    margin-top: 10px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    outline: none;
}

.action-btn:disabled{
    background-color: rgba(128, 128, 128, 0.5);
    cursor: not-allowed;
}