.notfound{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notfound>div{
    width: 100%;
    max-width: 600px;
    padding: 40px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.notfound h1{
    font-size: 5em;
    margin-bottom: 20px;
    font-family: century;
    font-style: italic;
    text-shadow: 0 1px 0 rgba(255, 217, 0, 0.4);
    pointer-events: none;
}

.notfound p{
    font-style: italic;
    font-size: 1.3em;
    font-family: Georgia, 'Times New Roman', Times, serif;
    margin-bottom: 50px;
    pointer-events: none;
}

.notfound .btn a{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: inherit;
}

@media screen and (max-width: 425px) {
    .notfound h1{
        font-size: 3.5em;
    }

    .notfound p{
        font-size: 1.1em;
    }
}