@font-face {
  font-family: "Digidon";
  src: url("./fonts/digidon/Digidon\ Demo.ttf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  --mainHeight: calc(100vh - 230px);
  /* font-family: 'Poppins', sans-serif; */
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

@media screen and (max-width: 425px) {
  * {
    --mainHeight: calc(100vh - 210px);
  }
}

*::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5em;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(35, 18, 11, 0.3);
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: #002f63;
  border: 0.5px solid rgb(242, 242, 242);
}

html {
  scroll-behavior: smooth;
}

main {
  min-height: var(--mainHeight);
  background-color: rgba(244, 246, 255, 0.7);
}

main:has(.profile, .notfound, form, .offline):not(main:has(.overview > nav)) {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

body {
  padding: 0 !important;
  --s: 100px; /* control the size */
  --c: #f4f6ff; /* first color */

  --_g: #0000 8%, var(--c) 0 17%, #0000 0 58%;
  background: linear-gradient(135deg, #0000 20.5%, var(--c) 0 29.5%, #0000 0) 0
      calc(var(--s) / 4),
    linear-gradient(45deg, var(--_g)) calc(var(--s) / 2) 0,
    linear-gradient(135deg, var(--_g), var(--c) 0 67%, #0000 0),
    linear-gradient(
      45deg,
      var(--_g),
      var(--c) 0 67%,
      #0000 0 83%,
      var(--c) 0 92%,
      #0000 0
    ),
    hsla(212, 100%, 19%, 0.1); /* second color */
  background-size: var(--s) var(--s);
  background-attachment: fixed;
}

section {
  padding: 0 20px;
}

.loader-item {
  background: linear-gradient(to top right, #dd4875 0%, #7f3b83 100%);
  border-radius: 10px;
  background-size: 200% 100%;
  animation: 2s shine linear infinite;
  color: transparent !important;
  cursor: progress;
  position: relative;
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

/* ========================================================================================

Banner Section

======================================================================================== */
.banner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #002f63;
  text-align: left;
  padding: 5px;
}

.banner-wrapper * {
  pointer-events: none;
}

.banner-wrapper img {
  width: 40px;
  object-fit: contain;
  margin-right: 5px;
}

.banner-wrapper .banner-imgwrap.second-imgwrap img {
  margin-right: initial;
  margin-left: 5px;
  width: 45px;
}

.banner-wrapper .banner-imgwrap {
  display: flex;
}

.banner-wrapper #banner-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.banner-wrapper .h1 {
  font-size: 20px;
  margin-bottom: -5px;
  letter-spacing: 5px;
}

.banner-wrapper .h2 {
  font-size: 14px;
}

.banner-wrapper .h3 {
  font-size: 10px;
}

/* ========================================================================================

Navbar Section

======================================================================================== */
#navbar {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  background-color: #ffc436;
  box-shadow: 0 0 5px #002f6380;
  color: white;
  position: sticky;
  top: 0;
  z-index: 4;
  transition: all 0.3s ease-in-out;
}

#navbar.transparent {
  opacity: 0.4;
  background-color: transparent;
}

#navbar.fixed {
  position: fixed;
}

#navbar a {
  text-decoration: none;
  color: inherit;
}

#navbar > ul {
  list-style-type: none;
  display: flex;
  justify-content: flex-end;
}

#navbar > ul > div {
  display: flex;
  align-items: center;
}

#navbar > ul > div > li {
  width: 100px;
  margin: 0 20px;
  text-align: center;
  font-weight: 500;
}

#navbar > ul > div > li > a {
  transition: all 0.2s ease-in-out;
}

#navbar > ul > div > li > a:hover,
#navbar > ul > div > li > a.active {
  color: #002f63;
  font-weight: 700;
  text-decoration: underline;
}

#navbar > ul > div > li > button,
.text-btn {
  width: 110px;
  height: 35px;
  color: white;
  border-radius: 5px;
  background-color: #002f63;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
  transform: scale(0.9);
  cursor: pointer;
}

.back-btn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  border-radius: 50%;
  background-color: #002f63;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
  cursor: pointer;
  font-size: 1.1em;
  position: fixed;
  top: 100px;
  left: 10px;
  opacity: 0.3;
  transition: box-shadow 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.back-btn:hover {
  opacity: 1;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5), 0 0 8px rgba(0, 0, 0, 0.5) inset;
}

.App:has(.overview > nav) .back-btn {
  top: 130px;
}

.text-btn > a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: inherit;
}

#navbar .md-menu {
  display: none;
}

.navclosediv {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: -1;
}

.login-div > li {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.login-div > li > ul {
  width: 100%;
  list-style-type: none;
  position: absolute;
  bottom: -120px;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.5s ease-in-out;
}

.login-div > li > button:hover ~ ul,
.login-div > li > button:focus ~ ul,
.login-div > li > ul:hover {
  transform: scaleY(1);
}

.login-div > li > ul > li {
  height: 40px;
  background-color: #002f63;
}

.login-div > li > ul > li > a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  transition: color 0.4s ease-in-out;
}

.login-div > li > ul > li > a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scaleX(0);
  transform-origin: right;
  background-color: transparent;
  z-index: -1;
  transition: transform 0.5s ease-in-out, background-color 0.5s ease-in-out;
  box-shadow: 0 -2px 3px #002f63;
}

.login-div > li > ul > li > a.active::after,
.login-div > li > ul > li > a:hover::after {
  background-color: #ffc436;
  transform: scaleX(1);
}

@media screen and (max-width: 800px) {
  #navbar .banner-wrapper img {
    width: 35px;
  }

  .banner-wrapper .banner-imgwrap.second-imgwrap img {
    width: 45px;
  }

  #navbar .banner-wrapper .h1 {
    font-size: 16px;
  }

  #navbar .banner-wrapper .h2 {
    font-size: 10px;
  }

  #navbar .banner-wrapper .h3 {
    font-size: 8px;
  }
}

@media screen and (max-width: 760px) {
  #navbar > ul > div > li {
    margin: 0;
  }

  #navbar .md-menu {
    display: initial;
  }

  #navbar > ul > .menus {
    width: 200px;
    height: 100vh;
    position: fixed;
    right: -210px;
    top: 0;
    flex-direction: column;
    background-color: #002f63;
    transition: right 0.5s ease-in-out;
    z-index: 5;
  }

  #showNav:checked ~ .menus {
    right: 0;
  }

  #showNav:checked ~ .navclosediv {
    pointer-events: initial;
  }

  #navbar > ul > .menus > li {
    width: 100%;
    height: 60px;
    box-shadow: 2px 0 3px rgba(242, 242, 242, 0.5);
  }

  #navbar > ul > .menus > li > a {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    transition: color 0.4s ease-in-out;
  }

  #navbar > ul > .menus > li > a::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scaleX(0);
    transform-origin: right;
    background-color: #ffc436;
    z-index: -1;
    transition: all 0.5s ease-in-out;
    box-shadow: 0 -2px 3px #002f63;
  }

  #navbar > ul > .menus > li > a.active::after,
  #navbar > ul > .menus > li > a:hover::after {
    transform: scaleX(1);
  }

  #navbar > ul > .menus > li > a.active,
  #navbar > ul > .menus > li > a:hover {
    text-decoration: none;
  }

  #navbar > ul > .menus > span {
    width: 50px;
    height: 50px;
    margin: 20px;
    color: white;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 5px rgba(242, 242, 242, 0.5);
    cursor: pointer;
    display: flex;
    font-size: 1.5em;
    align-items: center;
    justify-content: center;
    transition: box-shadow 0.3s ease-in-out;
  }

  #navbar > ul > .menus > span:hover {
    box-shadow: 0 0 10px rgba(242, 242, 242, 0.8);
  }
}

@media screen and (max-width: 701px) {
  .App:has(.admin-overview > nav) .back-btn {
    top: 130px;
  }

  .App:has(.admin-overview > nav > .student-search) .back-btn {
    top: 180px;
  }
}

@media screen and (max-width: 425px) {
  #navbar {
    height: 60px;
    padding: 0px 10px;
  }

  .back-btn {
    top: 80px;
  }

  .App:has(.admin-overview > nav) .back-btn {
    top: 110px;
  }

  .App:has(.admin-overview > nav > .student-search) .back-btn,
  .App:has(.overview > nav > .student-search) .back-btn {
    top: 160px;
  }

  #navbar .banner-wrapper {
    padding: 5px;
  }

  #navbar .banner-wrapper img {
    width: 28px;
    margin-right: 6px;
  }

  .banner-wrapper .banner-imgwrap.second-imgwrap img {
    margin-left: 6px;
    width: 35px;
  }

  #navbar .banner-wrapper .h1 {
    font-size: 14px;
    margin-bottom: -3px;
    letter-spacing: 5px;
  }

  #navbar .banner-wrapper .h2 {
    font-size: 8px;
  }

  #navbar .banner-wrapper .h3 {
    font-size: 6px;
  }

  main {
    min-height: calc(100vh - 210px);
  }
}

/* ========================================================================================

Footer Section

======================================================================================== */
footer {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 160px;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffc436;
  color: #002f63;
  box-shadow: -2px 0 5px rgba(0, 47, 99, 0.5);
}

footer .banner {
  padding: 8px;
  /* border-radius: 5px; */
  margin-bottom: 10px;
  /* box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); */
}

footer p {
  text-align: justify;
  text-align-last: center;
}

footer a {
  color: inherit;
  text-decoration: none;
}

@media screen and (max-width: 340px) {
  footer {
    font-size: 0.95em;
  }

  footer .banner {
    transform: scale(0.9);
    margin-bottom: 5px;
  }
}

/* ========================================================================================

Home Section

======================================================================================== */
.home {
  padding: 0;
  max-width: 100vw;
}

.home-main > div {
  width: 100%;
  padding: 20px;
  box-shadow: 0 0 3px rgba(0, 47, 99, 0.5);
  margin: 10px 0;
}

.classes-span {
  display: flex;
  padding: 10px 0;
}

.classes-span > div {
  width: 100%;
  padding: 20px 10px;
  box-shadow: 0 0 3px rgba(0, 47, 99, 0.5);
  margin: 5px 0;
}

.classes-span > div:nth-child(2) {
  margin: inherit 5px;
}

@media screen and (max-width: 910px) {
  .classes-span {
    flex-wrap: wrap;
    justify-content: center;
  }

  .classes-span > div {
    width: 100%;
    min-width: 300px;
    max-width: calc(100vw / 2);
    margin: 10px 0;
  }

  .classes-span > div:nth-child(2) {
    margin: inherit 0;
  }
}

.classes-span h1 {
  margin-bottom: 10px;
}

.home-main > div#contact {
  padding: 0;
}

.home-main > div:last-child {
  border-bottom: none;
}

#home-findus {
  margin-bottom: 15px !important;
}

#home-findus .heading1 {
  margin-bottom: 10px;
}

#home-findus .centres {
  background-color: initial;
  box-shadow: none !important;
}

#home-findus .classes {
  display: none;
}

.home-main > div:nth-child(1) > .heading1 {
  font-size: 2.5em;
  line-height: 60px;
}

.home-main > div:not(#contact) p {
  margin: 5px 0;
}

.home-main > div:nth-child(1) a {
  color: #002f63;
}

.home-main .home-btn-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 10px;
}

.home-main .home-btn {
  width: 120px;
  height: 30px;
  border-radius: 5px;
  background-color: #002f63;
  font-size: 0.9em;
  font-weight: bolder;
  margin: 0 10px;
  overflow: hidden;
  color: white;
  transition: border-radius 0.4s ease-in-out;
}

.home-main .home-btn:hover {
  border-radius: 25px;
}

.home-main .home-btn > a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
  text-decoration: none;
}

.home-class-info {
  display: flex;
  /* flex-wrap: wrap; */
  align-items: center;
  justify-content: space-evenly;
}

.home-class-info > img {
  max-height: 150px;
  border-radius: 5px;
  margin-right: 10px;
  padding: 3px;
  box-shadow: 0 0 5px rgba(0, 47, 99, 0.5);
}

.home-class-info > p {
  height: 100px;
  line-height: 25px;
  overflow: hidden;
}

.home-main > div:not(#contact) {
  display: flex;
  flex-direction: column;
}

.home-main > div:nth-child(1),
.home-main > div > div,
.classes-span,
.home-main #contact,
.home-main #home-findus,
.classes-info {
  max-width: 1440px;
  margin: auto;
}

.home-main > #home-findus .findus,
.home-main > #home-findus .centres {
  padding: 0;
}

.home-main > div#intro {
  max-width: 1200px;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: none;
}

.home-main > #intro > div > img {
  max-height: 240px;
  object-fit: contain;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  display: none;
}

@media screen and (max-width: 424px) {
  .home-class-info {
    flex-direction: column;
  }

  .home-class-info > img {
    max-width: 100%;
    max-height: 200px;
  }
}
.classes > ul > li > img {
  width: 120px;
  height: 100%;
  border-radius: 5px;
  margin-right: 10px;
}

.classes .text-btn {
  margin: 5px 0;
}

.classes .heading3 {
  text-align: left;
  line-height: 18px;
}

@media screen and (max-width: 940px) {
  .home > div {
    flex-direction: column;
  }

  .home > div > .home-info {
    width: 100%;
  }

  .classes > ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (max-width: 480px) {
  .classes > ul > li {
    max-width: 100%;
    justify-content: center;
  }

  .classes .class-info {
    width: calc(100% - 140px);
    font-size: 0.92em;
  }

  .classes .text-btn {
    transform: scale(0.8);
  }
}

@media screen and (max-width: 375px) {
  .home > div > div.classes {
    padding: 20px 5px;
  }

  .classes > ul > li {
    height: 120px;
  }

  .classes .class-info {
    font-size: 0.8em;
    line-height: 12px;
  }

  .classes .class-info > div:nth-child(1) {
    height: 76px;
    overflow: hidden;
  }

  .classes > ul > li > img {
    width: 100px;
  }

  .classes .text-btn {
    transform: scale(0.65);
    margin: 2px 0;
  }
}

/* ========================================================================================

Classes Info. Section

======================================================================================== */
.classes-info {
  padding: 20px;
}

.classes-info > h1 {
  margin-bottom: 20px;
}

.classes-info > div > img {
  float: left;
  width: 300px;
  max-width: 100%;
  max-height: 200px;
  border-radius: 5px;
  margin-right: 10px;
  padding: 2px;
  box-shadow: 0 0 3px rgba(0, 45, 97, 0.4);
}

.classes-info > div > div > p {
  margin: 5px 0;
  /* text-align: justify; */
}

.classes-info .btn {
  margin-top: 10px;
}

@media screen and (max-width: 460px) {
  .classes-info > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .classes-info > div > img {
    float: initial;
    margin: 0;
  }
}

/* ========================================================================================

Form

======================================================================================== */
.wrapper {
  position: relative;
  width: 400px;
  height: 400px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 20px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: transform 0.5s ease, height 0.3s ease, box-shadow 0.3s ease;
}

.wrapper:hover {
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.5);
}

.wrapper .form-box.forgotpassword {
  position: absolute;
  transition: none;
  transform: translateX(400px);
}

.wrapper.active-forgot {
  height: 360px;
}

.wrapper.active-forgot .form-box.forgotpassword {
  transition: transform 0.18s ease;
  transform: translateX(0);
}

.wrapper.active-forgot .form-box.login {
  transition: none;
  transform: translateX(-400px);
}

.wrapper .form-box {
  width: 100%;
  padding: 40px;
}

.form-box h2 {
  font-size: 2em;
  color: #002f63;
  text-align: center;
}

.input-box {
  position: relative;
  width: 100%;
  height: 50px;
  border-bottom: 2px solid #002f63;
  margin: 30px 0;
}

.input-box label {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  font-size: 1em;
  color: #002f63;
  font-weight: 500;
  pointer-events: none;
  transition: 0.5s;
}

.input-box label:has(svg) {
  display: flex;
  align-items: center;
}

.input-box label svg {
  margin-right: 5px;
}

.input-box > p {
  font-size: 0.8em;
  color: red;
  margin-top: 3px;
  font-style: italic;
}

.input-box input:focus ~ label,
.input-box input:not(:placeholder-shown) ~ label,
.input-box .inputs:focus ~ label,
.input-box .inputs:not(:placeholder-shown) ~ label {
  top: -5px;
}

.input-box input,
.inputs {
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  font-size: 1em;
  color: #002f63;
  font-weight: 600;
  padding: 0 35px 0 5px;
}

.input-box input[type="file"] {
  padding-top: 10px;
}

.input-box textarea {
  resize: none;
}

.input-box .icon {
  position: absolute;
  right: 8px;
  font-size: 1.2em;
  color: #002f63;
  line-height: 57px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  -moz-appearance: none;
  margin: 0;
}

input[type="datetime-local"],
input[type="date"] {
  position: relative;
}

input[type="datetime-local"]::-webkit-inner-spin-button,
input[type="datetime-local"]::-webkit-calendar-picker-indicator,
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  width: 100%;
  opacity: 0;
  position: absolute;
  /* display: none; */
  /* -webkit-appearance: none; */
}

input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.remember-forgot {
  font-size: 0.9em;
  color: #002f63;
  font-weight: 500;
  margin: -15px 0 15px;
  display: flex;
  justify-content: space-between;
}

.remember-forgot label {
  cursor: pointer;
}

.remember-forgot label input {
  accent-color: hsl(212, 100%, 19%);
  width: 10px;
  height: 10px;
}

.remember-forgot .forgot-link {
  color: #002f63;
  cursor: pointer;
}

.remember-forgot .forgot-link:hover {
  text-decoration: underline;
}

.login-register {
  font-size: 0.9em;
  color: #002f63;
  text-align: center;
  font-weight: 500;
  margin: 25px 0 10px;
}

.login-register p span {
  color: #002f63;
  font-weight: 600;
  cursor: pointer;
}

.login-register p span:hover {
  text-decoration: underline;
}

.btn {
  width: 100%;
  height: 45px;
  background: #002f63;
  border: none;
  outline: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1em;
  color: white;
  font-weight: 500;
}

.btn:disabled,
.logout:disabled,
.sp-btn:disabled {
  background-color: gray !important;
  cursor: not-allowed !important;
}

@media screen and (max-width: 425px) {
  .wrapper {
    width: 300px;
  }
  .wrapper .form-box {
    padding: 40px 30px;
  }
  .remember-forgot {
    font-size: 0.8em;
  }
}

/* ========================================================================================

Contact Section

======================================================================================== */
.contactform {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
}

.contactform > div {
  max-width: 90vw;
  display: flex;
  justify-content: center;
}

#contactform > div {
  width: 50%;
}

.lines {
  width: 50%;
  min-width: 300px;
  max-width: 100%;
  font-size: 14px;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #002f63;
}

.lines > h1 {
  font-size: 1.4em;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
}

.line {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.line > span {
  width: 210px;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.4);
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(1px);
  margin: 10px;
  transition: box-shadow 0.3s ease-in-out;
  position: relative;
}

.line > span > a {
  position: absolute;
  top: 15px;
  right: 15px;
}

.line > span:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.line > span h1 {
  font-size: 1.2em;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 0;
}

.line > span > h1 {
  margin-top: 0;
}

.line > span h1 > svg {
  margin-bottom: -2px;
}

.line > span > div > h1 {
  color: gray;
}

.line a {
  color: #002f63;
}

.line-email {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.line-email > div > p {
  width: 100%;
  margin: 10px 0;
  word-break: break-all;
}

@media screen and (max-width: 768px) {
  .contactform {
    flex-direction: column;
  }

  #contactform > div,
  .contactform > div {
    width: inherit;
  }

  .lines > h1 {
    margin-top: 20px;
  }
}

/* =============================================================================================

Registration Section

================================================================================================ */
.password-note {
  height: 0;
  display: flex;
  flex-direction: column;
  margin: 0;
  color: #23120b;
  transform: scale(0);
  transform-origin: top;
  transition: transform 0.3s linear, height 0.3s linear, margin 0.3s linear;
}

.input-box:has(.password-input:focus) ~ .password-note {
  height: 100%;
  margin: 15px;
  transform: scale(1);
}

.registration .wrapper:has(.password-input:focus) {
  height: 990px !important;
}

.registration.spec .wrapper:has(.password-input:focus) {
  height: 750px !important;
}

.adminreg .wrapper:has(.password-input:focus) {
  height: 450px !important;
}

.teacherreg .wrapper:has(.password-input:focus) {
  height: 790px !important;
}

.resetform .wrapper:has(.password-input:focus) {
  height: 660px !important;
}

.changeform .wrapper:has(.password-input:focus) {
  height: 540px !important;
}

.password-note ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  list-style-position: inside;
  font-size: 0.8em;
}

.password-note ul li {
  width: 50%;
  color: red;
  list-style-type: "×";
}

.password-note ul li.complete {
  list-style-type: "\2713";
  color: green;
}

.password-note ul li span {
  color: #23120b;
}

#strength,
#strength-bar {
  height: 3px;
  background-color: rgba(128, 128, 128, 0.5);
  border-radius: 10px;
  margin-bottom: 5px;
}

#strength-bar {
  background-color: green;
  transition: width 0.3s ease-in-out;
}

/* =============================================================================================

Overview Section

================================================================================================ */
.overview {
  padding: 0;
}

.overview > nav {
  width: 100%;
  height: 40px;
  background-color: #ffc436;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 80px;
  box-shadow: 0 5px 5px -5px rgba(0, 47, 99, 0.5);
  z-index: 3;
}

.overview > nav > ul {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  list-style-type: none;
  overflow-x: auto;
}

.overview > nav > ul::-webkit-scrollbar {
  display: none;
}

.overview > nav > ul > li {
  /* width: 100px; */
  height: 100%;
  margin: 0 20px;
  display: flex;
  align-items: center;
}

.overview > nav > ul > li > a {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-decoration: none;
  font-weight: 550;
  color: white;
}

.overview > nav > ul > li > a:hover,
.overview > nav > ul > li > a.active {
  text-decoration: underline;
  font-weight: 600;
  color: #002f63;
}

.overview > nav > div {
  position: relative;
}

.student-search {
  height: 100%;
  display: flex;
  align-items: center;
}

.overview > nav > div > input {
  width: 200px;
  height: 30px;
  border-radius: 5px;
  padding-left: 30px;
  color: #002f63;
  letter-spacing: 1px;
  border: 2px solid #002f63;
  outline: none;
  margin: 0 10px;
}

.overview > nav > div > svg {
  position: absolute;
  top: 13px;
  left: 20px;
  color: #002f63;
  cursor: pointer;
  font-size: 0.9em;
}

@media screen and (max-width: 460px) {
  .overview > nav:has(.student-search) {
    height: 90px;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 700px) {
  .admin-overview > nav:has(.student-search) {
    height: 90px;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
  }
}

.overview > div {
  width: 100%;
  min-height: calc(var(--mainHeight) - 40px);
  min-height: 300px;
  /* height: calc(100% - 40px); */
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

.overview-class {
  width: 100%;
  padding: 20px 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.overview-class > div {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: 20px 0 13px 0;
}

.overview-class > div > div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 20px 10px;
}

.overview-class > div > div:nth-child(odd) {
  background-color: rgba(255, 255, 255, 0.7);
}

.overview-class > div > div > h1 {
  width: calc(100% - 200px);
}

.overview-class > div > div > span:not(.checkbox-wrapper) {
  max-width: 200px;
  white-space: nowrap;
  overflow-x: auto;
}

.overview-class > div > div > span::-webkit-scrollbar {
  display: none;
}

.admin-overview-class > div > div > span {
  display: flex;
  align-items: center;
}

.admin-overview-class > div > div > span > a {
  height: 100%;
  display: flex;
  align-items: center;
}
.admin-overview-class > div > div > span svg {
  margin-left: 20px;
}

.overview-class > div > div > span > a {
  text-decoration: underline;
  color: #002f63;
}

.details-card-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  padding: 40px 20px;
}

.details-card {
  width: 220px;
  border-radius: 10px;
  background: linear-gradient(to top right, #dd4875 0%, #7f3b83 100%);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  position: relative;
  transition: transform 0.5s ease-in-out;
  cursor: pointer;
}

.details-card * {
  color: white;
}

.details-card.rotate {
  transform: rotateY(180deg);
}

.details-card > .ruc-watermark,
.ruc-watermark {
  width: 220px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.1;
  font-weight: bolder;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
}

.details-card > .ruc-watermark > div,
.ruc-watermark > div {
  animation: rotate 3.5s linear 2s infinite;
}

@keyframes rotate {
  to {
    transform: rotateY(360deg);
  }
}

.details-card > .ruc-watermark > div > img,
.ruc-watermark > div > img {
  width: 150px;
}

.details-card > .front,
.details-card > .back {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 10px;
}

.details-card > .front > div.details-img {
  width: 150px;
  max-height: 200px;
  pointer-events: none;
}

.details-card > .front > div.details-img > img {
  width: 100%;
  max-height: 200px;
  border-radius: 5px;
  box-shadow: 0 0 4px rgba(255, 255, 255, 0.8);
}

.details-card > .front > span:nth-child(2) {
  font-weight: 600;
  font-size: 1.2em;
  margin: 30px 0 10px 0;
  text-align: center;
  max-width: 95%;
}

.details-card > .front > span:nth-child(3) {
  font-weight: 400;
  font-size: 1em;
}

.details-card > .front > span.manage {
  width: 60px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 5px;
}

.details-card > .front > span.manage > a {
  color: white;
}

.details-card > .back {
  display: flex;
  flex-direction: column;
  text-align: center;
  transform: rotateY(-180deg);
}

.details-card > .back > div {
  width: 100%;
  padding: 8px 0;
  border-bottom: 1px solid white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.details-card > .back > div > span {
  word-wrap: break-word;
}

.details-card > .back > div:last-child {
  border-bottom: none;
}

.details-card > .back > div > h3 {
  font-size: 0.9em;
}

.overview-students {
  width: 100%;
  /* max-height: calc(var(--mainHeight) - 40px); */
  padding: 10px;
  /* overflow-y: auto; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.overview-students > nav {
  width: 100%;
}

@media screen and (max-width: 425px) {
  .overview > nav {
    top: 60px;
  }

  .overview > div {
    min-height: calc(var(--mainHeight) - 40px);
  }

  .overview-class > div > div > h1 {
    width: calc(100% - 150px);
  }

  .overview-class > div > div > span:not(.checkbox-wrapper) {
    max-width: 150px;
  }

  .admin-overview-class > div > div > h1 {
    width: calc(100% - 100px);
  }
}

.overview-students > nav > div {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

@media screen and (max-width: 922px) {
  .overview-students > nav > div {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 511px) {
  .overview-students > nav > div {
    grid-template-columns: 1fr 1fr;
  }
}

.filter-btn {
  min-height: 34px;
  padding: 6px 20px;
  font-style: normal;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  background-color: #ffead0;
  color: #4d4d4d;
  transition: border 200ms ease-in-out;
  cursor: pointer;
  margin: 5px 10px;
}

.filter-btn:focus {
  outline: none;
}

.filter-btn.selected:not(.clear-filters) {
  box-shadow: 0 0 3px #002f63;
  border: 2px solid white;
  background-color: #002f63;
  color: white;
}

.filter-btn.clear-filters {
  margin-left: -20px;
  height: 34px;
  padding: 6px 26px;
  font-style: normal;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  text-decoration: underline;
  background-color: transparent;
  color: #4d4d4d;
}

/* =============================================================================================

Profile Section

================================================================================================ */
.profile {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* =============================================================================================

Attendance Section

================================================================================================ */
.attendanceform {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 60px 0;
  position: relative;
}

.attendance-nav-btn {
  position: absolute;
  top: 3px;
  right: 10px;
}

.attendanceform .wrapper {
  height: 350px;
}

#optionswrapper {
  width: 180px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  list-style-type: none;
  background-color: #002f63;
  font-size: 15px;
  box-shadow: 0 1px 5px #002f63;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  opacity: 0;
  transform: scaleX(0);
  transform-origin: right;
  transition: all 500ms ease-in-out;
}

.attendance-nav-btn:focus + #optionswrapper,
#optionswrapper:hover {
  opacity: 1;
  transform: scaleX(1);
}

#optionswrapper > li > a {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: center;
  transition: all 600ms ease-in-out;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  color: white;
}

#optionswrapper > li > a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: #ffc436;
  box-shadow: 0 1px 5px #002f63;
  transition: transform 500ms ease-in-out;
  transform: scaleX(0);
  transform-origin: left;
}

#optionswrapper > li > a:hover::before,
#optionswrapper > li > a.active::before {
  transform: scaleX(1);
}

.attendanceform .statistics .wrapper {
  height: 200px;
  color: #002f63;
}

.statistics form > div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-weight: 500px;
  margin: 10px 0;
}

.statistics form > div > span {
  width: 50px;
}

.download-option {
  cursor: pointer;
  color: #002f63;
}

.download-option:hover {
  text-decoration: underline;
}

/* =============================================================================================

Confirm Sign-In

================================================================================================ */
.formwrapper.confirmsign > .wrapper > .form-box > form {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.formwrapper.confirmsign > .wrapper > .form-box > form > .back {
  position: absolute;
  top: 0;
  left: 0;
}
.formwrapper.confirmsign > .wrapper > .form-box > form > .back > a {
  color: #002f63;
}

.formwrapper.confirmsign > .wrapper > .form-box > form > .imgwrap {
  border-radius: 5px;
  overflow: hidden;
  max-width: 150px;
  max-height: 150px;
  box-shadow: 0 0 4px #002f63;
  padding: 2px;
  margin-top: 10px;
}

.formwrapper.confirmsign > .wrapper > .form-box > form > .imgwrap > img {
  max-width: 146px;
  max-height: 146px;
  border-radius: 5px;
  object-fit: contain;
  pointer-events: none;
}

.formwrapper.confirmsign > .wrapper > .form-box > form > p {
  margin: 10px 0;
}

/* =============================================================================================

Attendance Card

================================================================================================ */
div.attendance-card,
div.attendance-download-card {
  width: 200px;
  height: 140px;
  padding: 5px 0;
  margin: 10px 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #002f63;
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
}

.attendance-card:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
}

.attendance-card > .attendance-card-img {
  width: 100%;
}

div.attendance-download-card {
  cursor: default;
  justify-content: center;
  position: relative;
}

div.attendance-download-card .close {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 1.2em;
  cursor: pointer;
}

div.attendance-download-card button {
  width: 120px;
  margin-top: 12px;
  padding: 15px 5px;
  color: white;
  border-radius: 25px;
  background-color: #002f63;
  border: none;
  cursor: pointer;
}

/* =============================================================================================

Member Card

================================================================================================ */
.member-card {
  display: flex;
  flex-direction: column;
  width: 150px;
  min-width: 150px;
  height: 200px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  overflow: hidden;
  color: #002f63;
  text-decoration: none;
  background-color: white;
  margin: 10px 5px;
  transition: transform 0.3s ease-in-out;
}

.member-card:has(p.empty) {
  text-align: center;
}

.member-card:hover {
  transform: scale(1.05);
}

.member-card img {
  width: 100%;
  height: 150px;
}

.member-card h3 {
  width: 140px;
  overflow: hidden;
  margin-left: 5px;
  margin-top: 5px;
  white-space: nowrap;
  line-height: 20px;
  font-size: 0.98em;
}

.member-card p {
  margin-left: 5px;
  margin-top: 3px;
  font-size: 0.8em;
  line-height: 15px;
}

/* =============================================================================================

Class Card

================================================================================================ */
.class-card {
  display: flex;
  flex-direction: column;
  width: 200px;
  min-width: 200px;
  height: 130px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  overflow: hidden;
  color: #002f63;
  text-decoration: none;
  background-color: white;
  margin: 10px 5px;
  transition: transform 0.3s ease-in-out;
}

.class-card:hover {
  transform: scale(1.02);
}

.class-card img {
  width: 100%;
  height: 100px;
}

.class-card h3 {
  width: 190px;
  overflow: hidden;
  margin-left: 5px;
  margin-top: 5px;
  white-space: nowrap;
  line-height: 20px;
  text-align: center;
  font-size: 0.98em;
}

/* =============================================================================================

Registration Welcome Section

================================================================================================ */
.wrapper.welcome {
  width: 500px;
  height: auto;
  max-width: 95vw;
}

.wrapper.welcome .form-box {
  padding: 30px;
}

.wrapper.welcome .form-box > div {
  /* text-align: justify; */
  margin: 10px 0;
}

.wrapper.welcome .form-box > div > p,
.wrapper.welcome .form-box > div > div {
  margin: 5px 0;
}

.wrapper.welcome .form-box > div > div > ul > li {
  list-style-position: inside;
  list-style-type: "➤";
  padding-left: 5px;
}

.wrapper.welcome .form-box > div > div > ul.checkmark > li {
  list-style-type: "\2713";
}

@media screen and (max-width: 425px) {
  .wrapper.welcome .form-box {
    padding: 30px 20px;
  }
}

/* =============================================================================================

Find Us Section

================================================================================================ */
.findus {
  width: 100%;
  padding: 0;
  padding-top: 20px;
}

.findus .heading1 {
  margin-bottom: 20px;
}

.findus .centres {
  width: 100%;
  padding: 10px 20px;
  box-shadow: 0 2px 3px rgba(0, 45, 97, 0.3);
  margin-bottom: 10px;
}

.findus .centres > div {
  width: 100%;
  display: flex;
  overflow-x: auto;
}

.findus .centres > div::-webkit-scrollbar {
  display: none;
}

.findus .classes > div {
  padding: 10px 0;
  box-shadow: 0 -2px 3px rgba(0, 45, 97, 0.3);
}

.findus .classes .heading2 {
  margin: 20px 0;
}

.findus .classes > div > div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-top: 1px dashed #002f63;
}

.findus .classes > div > div > span {
  width: calc(100% - 205px);
  word-wrap: break-word;
  margin-left: 5px;
  text-align: left;
}

@media screen and (max-width: 424px) {
  .findus .classes > div > div {
    flex-direction: column;
  }

  .findus .classes > div > div > span {
    width: 100%;
    text-align: center;
  }
}

/* =============================================================================================

Utilities

================================================================================================ */
.heading1,
.heading2,
.heading3 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.8em;
  color: #002f63;
  text-align: center;
}

.heading2 {
  font-size: 1.4em;
}

.heading3 {
  font-size: 1.1em;
}

select {
  cursor: pointer;
}

.spinner {
  animation: spin infinite 5s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* =============================================================================================

Status Message

================================================================================================ */
.status-wrapper {
  width: auto;
  min-width: 200px;
  max-width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  margin: 5px 0;
}

.status-message {
  width: 100%;
  text-align: center;
  font-size: 14px;
  padding: 10px 20px;
  padding-right: 35px;
  background-color: #62ddf9;
  border: 1px solid white;
  color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.3);
}

.status-message.success {
  background-color: #90e18d;
}

.status-message.failed {
  background-color: red;
}

.status-close {
  position: absolute;
  right: 10px;
  color: white;
  cursor: pointer;
}

/* =============================================================================================

File Upload

================================================================================================ */
.upload-status {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#upload-progress,
#upload-progress-bar {
  width: 300px;
  max-width: 95%;
  height: 20px;
  background-color: rgba(128, 128, 128, 0.5);
  border-radius: 25px;
  margin: 10px 0;
  position: relative;
}

#upload-progress > label {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#upload-progress-bar {
  max-width: 100%;
  margin: 0;
  background-color: green;
  transition: width 0.3s ease-in-out;
}

/* =============================================================================================

Empty Search

================================================================================================ */
.emptysearch {
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.emptysearch svg {
  width: 300px;
  max-width: 90vw;
  border-radius: 3px;
  fill: none;
  stroke: #002f63;
  stroke-linecap: round;
  stroke-width: 8%;
}

.emptysearch use {
  stroke: #ffc436;
  animation: a 3s linear infinite;
}

.emptysearch h3 {
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 2.2em;
  color: #002f63;
  font-style: italic;
  text-align: center;
}

@keyframes a {
  to {
    stroke-dashoffset: 0px;
  }
}

/* =============================================================================================

Checkbox

================================================================================================ */
.checkbox-wrapper .tgl {
  display: none;
}

.checkbox-text {
  font-size: 0.8em;
}

.checkbox-wrapper .tgl,
.checkbox-wrapper .tgl:after,
.checkbox-wrapper .tgl:before,
.checkbox-wrapper .tgl *,
.checkbox-wrapper .tgl *:after,
.checkbox-wrapper .tgl *:before,
.checkbox-wrapper .tgl + .tgl-btn {
  box-sizing: border-box;
}

.checkbox-wrapper .tgl::-moz-selection,
.checkbox-wrapper .tgl:after::-moz-selection,
.checkbox-wrapper .tgl:before::-moz-selection,
.checkbox-wrapper .tgl *::-moz-selection,
.checkbox-wrapper .tgl *:after::-moz-selection,
.checkbox-wrapper .tgl *:before::-moz-selection,
.checkbox-wrapper .tgl + .tgl-btn::-moz-selection,
.checkbox-wrapper .tgl::selection,
.checkbox-wrapper .tgl:after::selection,
.checkbox-wrapper .tgl:before::selection,
.checkbox-wrapper .tgl *::selection,
.checkbox-wrapper .tgl *:after::selection,
.checkbox-wrapper .tgl *:before::selection,
.checkbox-wrapper .tgl + .tgl-btn::selection {
  background: none;
}

.checkbox-wrapper .tgl + .tgl-btn {
  outline: 0;
  display: block;
  width: 8em;
  height: 2em;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-shadow: 0 0 3px rgba(0, 45, 97, 0.3);
}

.checkbox-wrapper .tgl + .tgl-btn:after,
.checkbox-wrapper .tgl + .tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}

.checkbox-wrapper .tgl + .tgl-btn:after {
  left: 0;
}

.checkbox-wrapper .tgl + .tgl-btn:before {
  display: none;
}

.checkbox-wrapper .tgl:checked + .tgl-btn:after {
  color: #002f63;
  text-shadow: 0 1px 0 rgba(255, 217, 0, 0.4);
  left: 50%;
}

.checkbox-wrapper .tgl:checked + .tgl-btn2:after {
  color: green;
}

.checkbox-wrapper .tgl-skewed + .tgl-btn {
  overflow: hidden;
  transform: skew(-10deg);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 0.2s ease;
  background: #fff;
}

.checkbox-wrapper .tgl-skewed + .tgl-btn3 {
  background: #f4f6ff;
}

.checkbox-wrapper .tgl-skewed + .tgl-btn:after,
.checkbox-wrapper .tgl-skewed + .tgl-btn:before {
  transform: skew(10deg);
  display: inline-block;
  transition: all 0.2s ease;
  width: 100%;
  text-align: center;
  position: absolute;
  line-height: 2em;
  font-weight: bold;
  color: red;
  text-shadow: 0 1px 0 rgba(0, 45, 97, 0.4);
}

.checkbox-wrapper .tgl-skewed + .tgl-btn2:after,
.checkbox-wrapper .tgl-skewed + .tgl-btn2:before {
  color: #002f63;
  text-shadow: 0 1px 0 rgba(255, 217, 0, 0.4);
}

.checkbox-wrapper .tgl-skewed + .tgl-btn:after {
  left: 100%;
  content: attr(data-tg-on);
}

.checkbox-wrapper .tgl-skewed + .tgl-btn:before {
  left: 0;
  content: attr(data-tg-off);
}

.checkbox-wrapper .tgl-skewed + .tgl-btn:active {
  background: #fff;
}

.checkbox-wrapper .tgl-skewed + .tgl-btn3:active {
  background: #f4f6ff;
}

.checkbox-wrapper .tgl-skewed + .tgl-btn:active:before {
  left: -10%;
}

.checkbox-wrapper .tgl-skewed:checked + .tgl-btn {
  background: #ffffff;
}

.checkbox-wrapper .tgl-skewed:checked + .tgl-btn3 {
  background: #f4f6ff;
}

.checkbox-wrapper .tgl-skewed:checked + .tgl-btn:before {
  left: -100%;
}

.checkbox-wrapper .tgl-skewed:checked + .tgl-btn:after {
  left: 0;
}

.checkbox-wrapper .tgl-skewed:checked + .tgl-btn:active:after {
  left: 10%;
}

/* =============================================================================================

Sweet Alert

================================================================================================ */
.swal2-show {
  border: 3px solid #002f63 !important;
  max-width: 90vw !important;
}
.swal2-cancel {
  background-color: #ffc436 !important;
  color: white !important;
}
.swal2-text {
  text-align: center;
}

.swal2-confirm {
  background-color: #002f63 !important;
  color: white !important;
}

.swal2-popup .swal2-styled:focus {
  box-shadow: none !important;
}

/* =============================================================================================

Home Carousel

================================================================================================ */
.carousel img {
  display: block;
  width: 100%;
  height: 100vh;
  max-height: 600px;
  object-fit: cover;
  pointer-events: none;
}

.carousel_title {
  pointer-events: none;
  position: absolute;
  color: white;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  font-family: "Digidon", "Poppins", sans-serif;
  font-size: 2.5em;
  line-height: 60px;
  text-align: center;
  text-shadow: 0 0 5px #002f63;
  opacity: 0.6;
  transition: opacity 0.2s ease-in-out;
}

.home .carousel-caption {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  font-size: 1.2em;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.3);
}

.home .carousel-caption * {
  font-family: Georgia, "Times New Roman", Times, serif;
}

.home .carousel-caption h3 {
  margin-top: 10px;
}

.home:has(.carousel-item.active .hide-title) .carousel_title {
  opacity: 0.1;
}

.carousel-indicators {
  list-style-type: none;
}

.carousel-indicators li {
  width: 30px;
  border-bottom: 2px solid white;
  margin: 0 5px;
  opacity: 0.4;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.carousel-indicators li.active {
  opacity: 1;
}

.carousel-control-next,
.carousel-control-prev {
  opacity: 0 !important;
  cursor: default !important;
}

/* =============================================================================================

Home Images

================================================================================================ */
.attendanceform:has(.home-images) {
  align-items: initial;
  justify-content: initial;
  padding: 25px 0;
}

.attendanceform:has(.home-images) > div {
  width: 100%;
}

.home-images {
  width: 100%;
}

.home-images > div {
  margin-top: 10px;
  padding: 0 10px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.home-images > div .class-card > span {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.home-images > div .class-card > span > a {
  color: inherit;
}

.home-images > div .class-card > span > svg {
  cursor: pointer;
}
