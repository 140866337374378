.christmas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;
  z-index: 998;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.lightrope {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  margin-top: -12px;
  z-index: 1;
  padding: 0;
  pointer-events: none;
  opacity: 0.3;
  transform: scale(0.7);
}

.lightrope.inverse {
  margin-bottom: -12px;
  transform: rotateZ(180deg) scale(0.7);
}

.lightrope li {
  position: relative;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;
  width: 12px;
  height: 28px;
  border-radius: 50%;
  margin: 20px;
  display: inline-block;
  background: rgba(0, 247, 165, 1);
  box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 1);
  animation-name: flash-1;
  animation-duration: 2s;
}

.lightrope li:nth-child(2n + 1) {
  background: rgba(0, 255, 255, 1);
  box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.5);
  animation-name: flash-2;
  animation-duration: 0.4s;
}

.lightrope li:nth-child(4n + 2) {
  background: rgba(247, 0, 148, 1);
  box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 1);
  animation-name: flash-3;
  animation-duration: 1.1s;
}

.lightrope li:nth-child(odd) {
  animation-duration: 1.8s;
}

.lightrope li:nth-child(3n + 1) {
  animation-duration: 1.4s;
}

.lightrope li:before {
  content: "";
  position: absolute;
  background: #222;
  width: 10px;
  height: 9.3333333333px;
  border-radius: 3px;
  top: -4.6666666667px;
  left: 1px;
}

.lightrope li:after {
  content: "";
  top: -14px;
  left: 9px;
  position: absolute;
  width: 52px;
  height: 18.6666666667px;
  border-bottom: solid #222 2px;
  border-radius: 50%;
}

.lightrope li:last-child:after {
  content: none;
}

.lightrope li:first-child {
  margin-left: -40px;
}

@keyframes flash-1 {
  0%,
  100% {
    background: rgba(0, 247, 165, 1);
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 1);
  }
  50% {
    background: rgba(0, 247, 165, 0.4);
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 247, 165, 0.2);
  }
}

@keyframes flash-2 {
  0%,
  100% {
    background: rgba(0, 255, 255, 1);
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 1);
  }
  50% {
    background: rgba(0, 255, 255, 0.4);
    box-shadow: 0px 4.6666666667px 24px 3px rgba(0, 255, 255, 0.2);
  }
}

@keyframes flash-3 {
  0%,
  100% {
    background: rgba(247, 0, 148, 1);
    box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 1);
  }
  50% {
    background: rgba(247, 0, 148, 0.4);
    box-shadow: 0px 4.6666666667px 24px 3px rgba(247, 0, 148, 0.2);
  }
}

body:has(.christmas) .christmas-hat,
body:has(.christmas) button.btn {
  position: relative;
}

body:has(.christmas) .christmas-hat::before,
body:has(.christmas) button.btn::before {
  content: " ";
  position: absolute;
  top: -16px;
  right: -10px;
  width: 40px;
  height: 40px;
  background: url(../images/christmas-hat.png);
  background-size: 40px;
  background-repeat: no-repeat;
}

body:has(.christmas) .christmas-hat::after,
body:has(.christmas) button.btn::after {
  content: " ";
  position: absolute;
  bottom: -25px;
  left: -5px;
  width: 30px;
  height: 30px;
  background: url(../images/christmas_ball.png);
  background-size: 30px;
  background-repeat: no-repeat;
}
