.pagination-wrapper{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9em;
    margin: 10px 0;
}

.pagination{
    display: flex;
    list-style-type: none;
    background-color: #002F63;
    border-radius: 20px;
    height: 40px;
    overflow: hidden;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.6);
}

.page-item{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 28px;
}

.page-next, .page-prev{
    width: 35px;
}

.page-link{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    color: white;
}

.page-item:not(.page-active, .page-click):hover, .page-active, .page-click{
    font-size: 1.1em;
    font-weight: bolder;
}

.page-active{
    background-color: none;
}

.page-click{
    background-color: #FFD700;
    font-weight: bolder;
}

@media screen and (max-width: 320px) {
    .pagination{
        transform: scale(0.9);
    }
}